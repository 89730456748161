import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";


// Colored icons
import dashboardIconColored from "../assets/svgs/dashboard-Embeded/KPI Dashboard.svg";
import shiftIconColored from "../assets/svgs/dashboard-Embeded/Shift Management.svg";
import dumpIconColored from "../assets/svgs/dashboard-Embeded/Dump Management.svg";
import kpiIconColored from "../assets/svgs/dashboard-Embeded/KPI Dashboard.svg";
import maintainanceIconColored from "../assets/svgs/dashboard-Embeded/Maintenance.svg";
import preStartChecksIconColored from "../assets/svgs/dashboard-Embeded/Pre-Start Checks.svg";
import assetIconColored from "../assets/svgs/dashboard-Embeded/Asset Tracking.svg";
import historyIconColored from "../assets/svgs/sidebar/history_colored.png";
import userIconColored from "../assets/svgs/dashboard-Embeded/Manage Users.svg";
import notificationsIconColored from "../assets/svgs/sidebar/notifications_colored.png";
import settingsIconColored from "../assets/svgs/sidebar/settings_colored.png";


const coloredIcons = {
  dashboard: dashboardIconColored,
  planning: shiftIconColored,
  dump: dumpIconColored,
  kpi: kpiIconColored,
  maintainance: maintainanceIconColored,
  preStartChecks: preStartChecksIconColored,
  asset: assetIconColored,
  history: historyIconColored,
  user: userIconColored,
  notifications: notificationsIconColored,
  settings: settingsIconColored,
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [routes, setRoutes] = useState(null);
  const [activeRoute, setActiveRoute] = useState("");

  // All routes definition
  const AllRoutes = {
    "shift-management": {
      displayName: "Shift Management",
      name: "planning",
      subroutes: [
        { displayName: "Shift Planning", pathName: "/shift-management/shift-planning", name: "planning" },
        { displayName: "Dashboard", pathName: "/shift-management/dashboard", name: "dashboard" },
        { displayName: "Dump Cycle Tracker", pathName: "/shift-management/dump-cycle-tracker", name: "dump" },
        { displayName: "History", pathName: "/shift-management/history", name: "history" },
      ],
    },
    "dump-management": {
      displayName: "Dump Management",
      name: "dump",
      subroutes: [{ displayName: "Dump Management", pathName: "/dump-management/landing-page", name: "dump" }],
    },
    "asset-tracking": {
      displayName: "Asset Tracking",
      name: "asset",
      subroutes: [{ displayName: "Asset Tracking", pathName: "/asset-tracking/landing-page", name: "asset" }],
    },
    "user-management": {
      displayName: "User Management",
      name: "user",
      subroutes: [{ displayName: "User Management", pathName: "/user-management/landing-page", name: "user" }],
    },
    "management-kpis": {
      displayName: "KPI Management",
      name: "kpi",
      subroutes: [
        { displayName: "Asset Analytics", pathName: "/management-kpis/asset-analytics", name: "kpi" },
        { displayName: "Delays", pathName: "/management-kpis/delays", name: "kpi" },
      ],
    },
  };

  // Hide sidebar logic
  const HideNav = () => {
    const leftSidebar = document.getElementById("LeftSidebar");
    const rightSidebar = document.getElementById("RightSidebar");
    leftSidebar.classList.toggle("hide_sidebar");
    rightSidebar.classList.toggle("full_dashboard");
  };

  // Update route on path change
  useEffect(() => {
    const newPaths = location.pathname?.split('/');
    const currentRoute = newPaths[1];
    if (currentRoute !== activeRoute) {
      setRoutes(AllRoutes[currentRoute]);
      setActiveRoute(currentRoute);
    }
  }, [location]);

  return (
    <aside className="left_sidebar" id="LeftSidebar" style={{ backgroundColor: "#f7f7f4" }}>
      <div className="left_sidebar_top">
        <div className="dashboard_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="toggle_design" onClick={HideNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <hr style={{ backgroundColor: "#E1E8F466", borderWidth: "0.1rem 0" }} />
      <nav className="sidebar_menu">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={coloredIcons[routes?.name]} alt="" style={{ width: "70%" }} />
            <h2 style={{ textAlign: "center", marginTop: "30px" }}>{routes?.displayName}</h2>
          </div>
          <div style={{ width: "80%", marginTop: "30px", display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", width: "100%" }}>
              {routes?.subroutes?.map((route, index) => (
                <Link to={route.pathName} key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", width: "30%", userSelect: 'none' }}>
                  <div
                    style={{
                      borderRadius: "15px",
                      background: location.pathname.includes(route.pathName.split("/")[2]) ? "#4F74D9" : "#fff",
                      width: "4.5rem",
                      height: "4.5rem",
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    to={route.pathName}
                  >
                    <img
                      src={coloredIcons[route.name]}
                      alt={route.name}
                      style={{ width: "70%" }}
                    />
                  </div>
                  <p style={{
                    marginTop: "5px",
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "12px",
                    whiteSpace: "wrap",
                    color: "black",
                  }}>
                    {route.displayName}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </aside >
  );
};

export default Sidebar;
