import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";

export default function AuthLayout({ children, pageName, noSideBar, showlogo }) {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }


  return (
    <section className="manage_dashboard">
      {!noSideBar ? (
        <>
          <Helmet>
            <title>{`${pageName} | iMOS`}</title>
          </Helmet>

          <Sidebar />

          <div className="right_sidebar" id="RightSidebar">
            <div
              className="top_header"
            >
              <Header pageName={pageName} />
            </div>
            {children}
          </div>
        </>
      ) : (
        <div className="  " id="RightSidebar">
          <div className="top_header_blank" style={{ opacity: 1, background: "transparent linear-gradient(180deg, #EDF6FF 0%, #E0EAFD 100%) 0% 0% no-repeat padding-box;" }}>
            <Header pageName={""} showlogo />
          </div>
          {children}
        </div>
      )}
    </section>
  );
}

