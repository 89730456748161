import { ButtonGroup, Dropdown, Form, Image } from "react-bootstrap";
import { CallWithAuth, CallWithAuthFormData } from "../../action/apiActions";
import { FiDownload, FiWind } from "react-icons/fi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { toast } from "react-toastify";
import {
  SHIFT_DASHBOARD_LISTING_DETAILS,
  SHIFT_HOUR_DATA,
  GET_WEBHOOK_DATA,
  GET_PRESENT_TOMMOROW_DATA,
  GET_PRESENT_FORECAST_DATA,
  GEt_ALL_USER_WITH_FILTER,
  END_SHIFT,
} from "../../action/apiPath";
import WeatherReport from "../../components/WeatherReport/WeatherReport";
import { MdOutlineFilterList } from "react-icons/md";
import { ImEnlarge2 } from "react-icons/im";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import AuthLayout from "../../components/AuthLayout";
import HourlyResult from "../HourlyResult";
import { IoIosRainy } from "react-icons/io";
import ProgressBar from "../../components/ProgressBar";
import { WiDust } from "react-icons/wi";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import useAuth from "../../hooks/useAuth";
import LoadingScreen from "../../components/LoadingScreen";
import ExcavatorsCard from "../../components/Dashboard/ExcavatorsCard";
import "./style.css";
import { utcToNZTime, getAlert, timeDiff } from "../../utils/helper";
import FilterModal from "./FilterModal";
import TrendArrow from "../../components/TrendArrow";
import ConfirmationPopup from "./Popup";
import DynamicSelectField from "../ShiftPlanning/components/DynamicSelectField/DynamicSelectField";

const Dashboard = ({ ...props }) => {

  const { myRoles } = useContext(AuthContext)
  const [filterVariableAfterApply, setFilterVariableAfterApply] = useState({
    Excavator: [],
    Status: [],
    Operator: [],
    Truck: [],
  });
  const filterVariableRef = useRef({
    Excavator: [],
    Status: [],
    Operator: [],
    Truck: [],
  });
  const isFilterAppliedRef = useRef(0);
  const [shiftsOptions, setShiftsOptions] = useState([])

  useEffect(() => {
    const filterCount = Object.values(filterVariableAfterApply).reduce(
      (accumulator, currentValue) =>
        currentValue.length !== 0 ? accumulator + 1 : accumulator,
      0
    );
    if (filterCount > 0) {
      setisFilterApplied(true);
    } else {
      setisFilterApplied(false);
    }
    isFilterAppliedRef.current = filterCount;
    filterVariableRef.current = filterVariableAfterApply;
    // filterData();
  }, [filterVariableAfterApply]);

  const filterData = (excavator) => {
    const excavatorMatched =
      filterVariableRef.current.Excavator.length === 0 ||
      filterVariableRef.current.Excavator.some(
        (filterExcavator) => filterExcavator.label === excavator.name.Unit
      );
    const statusMatched =
      filterVariableRef.current.Status.length === 0 ||
      filterVariableRef.current.Status.some((status) => {
        const isFallingBehind = behindTargetExcavators?.includes(
          excavator.name.Unit
        );
        const isNotOperating = notOperatingExcavators?.includes(
          excavator.name.Unit
        );
        const isBehindTarget =
          excavator.actualLoads < excavator.targetLoads && !isNotOperating;
        const isTargetComplete =
          excavator.actualLoads >= excavator.targetLoads && !isNotOperating;

        switch (status.value) {
          case 0:
            return isTargetComplete;
          case 1:
            return isBehindTarget;
          case 2:
            return isFallingBehind;
          case 3:
            return isNotOperating;
        }
      });
    const operatorMatched =
      filterVariableRef.current.Operator.length === 0 ||
      filterVariableRef.current.Operator.some(
        (operator) => operator.value === excavator.operator._id
      );
    const truckMatched =
      filterVariableRef.current.Truck.length === 0 ||
      filterVariableRef.current.Truck.some((truck) =>
        excavator.haulTrucks.some(
          (haulTruck) => haulTruck.label === truck.label
        )
      );
    const truckOperatorMatched =
      filterVariableRef.current.Operator.length === 0 ||
      filterVariableRef.current.Operator.some((operator) => {
        return excavator.haulTrucks.some(
          (haulTruck) =>
            trucks.find((truck) => truck.equipment === haulTruck._id)?.operator
              ._id === operator.value
        );
      });

    // Return true if all filter conditions are met, false otherwise
    return (
      excavatorMatched &&
      statusMatched &&
      (operatorMatched || truckOperatorMatched) &&
      truckMatched
    );
  };

  const radios = [
    { name: "Shift totals", value: 1 },
    { name: "Hourly results", value: 2 },
  ];

  const [todayData, setTodayData] = useState({
    temperature: "",
    max_temp: "",
    min_temp: "",
    rain_so_far: "",
    feels_like: "",
  });
  const [tomorrowData, setTomorrowData] = useState({
    cloud_cover: "",
    humidity: "",
    max_temp: "",
    min_temp: "",
    rain_so_far: "",
    uv: "",
    wind_direction: "",
    wind_gusts: "",
    wind_speed: "",
    pressure: "",
  });
  const [webhookData, setWebHookData] = useState({
    temp: "",
    wind: "",
    windDirection: "",
    pressure: "",
    rain: "",
    dust: "",
  });
  const [forecast, setForecast] = useState({
    temp: [],
    cloudCover: [],
  });
  const { auth } = useAuth();
  const isFetchingTodayTomorrowData = useRef(false);
  const isFetchingForcastData = useRef(false);
  const [shiftAlert, setShiftAlert] = useState(false);
  const isFetchingWebhookData = useRef(false);
  const { shiftData, setShiftData } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("Dictamen");
  const [radioValue, setRadioValue] = useState(1);
  const [notOperatingTrucks, setNotOperatingTrucks] = useState([]);
  const [notOperatingExcavators, setNotOperatingExcavators] = useState([]);
  const [behindTargetExcavators, setBehindTargetExcavators] = useState([]);
  const [behindTargetTrucks, setBehindTargetTrucks] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [trucks, setTrucks] = useState([]);
  const [shiftStartTime, setShiftStartTime] = useState("");
  const [excavators, setExcavators] = useState([]);
  const isDataFetching = useRef(false);
  const [selectedDate, setSelectedDate] = useState("");
  // const [totalTimeElapsed, setTotalTimeElapsed] = useState(0)
  const [topSlider, setTopSlider] = useState(true);
  const [hasOneHourElapsed, setHasOneHourElapsed] = useState(false);
  const [shiftStartingAndEndingTime, setShiftStartingAndEndingTime] = useState({
    start: "",
    end: "",
  });
  const selectedDateRef = useRef("");
  const [isFilterModalOpen, setisFilterModalOpen] = useState(false);

  const excavatorFreshData = useRef([]);
  const timeoutRef = useRef();
  const [isFilterApplied, setisFilterApplied] = useState(false);
  const [optionsForTruck, setoptionsForTruck] = useState([]);
  const [optionsForExacavator, setoptionsForExacavator] = useState([]);
  const [optionsForOperator, setoptionsForOperator] = useState([]);
  const [breakdownExcavatorsOldData, setbreakdownExcavatorsOldData] = useState(
    []
  );
  const [optionsForStatus, setoptionsForStatus] = useState([
    {
      label: "Reached target",
      value: 0,
    },
    {
      label: "Below target",
      value: 1,
    },
    {
      label: "Falling behind",
      value: 2,
    },
    {
      label: "Not operating",
      value: 3,
    },
  ]);
  const [shiftType, setShiftType] = useState(0);
  const [selectedShift, setSelectedShift] = useState({
    day: undefined,
    night: undefined
  })
  const shiftTypeRef = useRef(0);
  const selectedShiftRef = useRef({
    day: undefined,
    night: undefined
  });

  const [searchName, setsearchName] = useState("");
  const screenLoadedRef = useRef(false);
  const valueRef = useRef(1);
  const intervalId = useRef(null);

  const setDefaultScreen = (dayNight) => {
    if (screenLoadedRef.current) {
      if (radioValue == 1) {
        valueRef.current = 2;
        setRadioValue(2);
      } else {
        valueRef.current = 1;
        setRadioValue(1);
      }
    }
    screenLoadedRef.current = true;
    if (shiftType != dayNight) {
      shiftTypeRef.current = dayNight;
      setShiftType(dayNight);
    }
    // const startTime = new Date();
    // const endTime = new Date();
    // startTime.setHours(5);
    // startTime.setMinutes(45);
    // startTime.s etSeconds(0);
    // endTime.setHours(17);s
    // endTime.setMinutes(0);
    // endTime.setSeconds(0);
    // const now = new Date().getTime();
    // if (now >= startTime.getTime() && now <= endTime.getTime()) {
    //   shiftTypeRef.current = 0;
    //   setShiftType(0);
    // } else {
    //   shiftTypeRef.current = 1;
    //   setShiftType(1);
    // }
  };

  useEffect(() => {
    if (window.location.href.includes("tvdashboard")) {
      if (intervalId.current) {
        clearTimeout(intervalId.current);
      }
      let excavatorTime = excavators?.length ? excavators?.length * 20000 : 20000
      let time = radioValue == 1 ? 20000 : excavatorTime
      intervalId.current = setTimeout(() => {
        if (screenLoadedRef.current) {
          setDefaultScreen(shiftTypeRef.current);
        }
      }, time);
    }
  }, [radioValue]);

  const callOperator = async () => {
    let payload = {
      NUMBER_OF_ITEMS: 300,
      PAGE_NUMBER: 1,
      crew: "",
      endDate: "",
      equipmentLicense: "",
      key: "",
      startDate: "",
      userType: 200,
    };
    await CallWithAuth("POST", GEt_ALL_USER_WITH_FILTER, payload).then(
      async (res) => {
        if (res?.res?.data?.status == 200) {
          setoptionsForOperator(
            res?.res?.data?.data?.result.map((o) => ({
              label: o?.firstName + " " + o?.lastName,
              value: o?._id,
            }))
          );
        }
      }
    );
  };

  useEffect(() => {
    let timeout;
    selectedDateRef.current = selectedDate;
    const fetchData = async () => {
      const { userData } = auth || {};
      const { current: selectedDate } = selectedDateRef;

      // Fetch shifts data
      const shiftResponse = await CallWithAuthFormData("POST", "/api/getShiftsForDashboard", {
        userId: userData?._id,
        date: selectedDate,
      });

      const shiftsData = shiftResponse?.res?.data?.data || {};
      setShiftsOptions({
        day: shiftsData.day,
        night: shiftsData.night
      });

      // const { day: selectedDay, night: selectedNight } = selectedShiftRef.current;
      // const dayShiftExists = !shiftsData.day?.length || shiftsData.day?.some(sd => sd.value === selectedDay?.value);
      // const nightShiftExists = !shiftsData.night?.length || shiftsData.night?.some(sn => sn.value === selectedNight?.value);

      // if (!dayShiftExists || !nightShiftExists) {
      //   const updatedShift = {
      //     day: dayShiftExists ? selectedDay : shiftsData.day?.[0],
      //     night: nightShiftExists ? selectedNight : shiftsData.night?.[0]
      //   }
      //   setSelectedShift(updatedShift);
      //   selectedShiftRef.current = updatedShift;
      // }

      const updatedShift = {
        day: shiftsData?.day?.[0],
        night: shiftsData?.night?.[0]
      }
      setSelectedShift(updatedShift);
      selectedShiftRef.current = updatedShift;

      timeout = setTimeout(() => {
        fetchData()
      }, 60000);
    }

    setLoading(true);
    fetchData()
    return () => {
      if (timeout)
        clearTimeout(timeout);
    }
  }, [selectedDate])

  useEffect(() => {
    let timeout;
    async function fetchFunc() {
      // console.log("start fetching", shiftTypeRef.current, selectedShiftRef.current);

      await fetchData();
      // console.log("end fetching", shiftTypeRef.current, selectedShiftRef.current);

      // Set a 15-second timeout for the next call
      timeout = setTimeout(fetchFunc, 15000);
    }

    // if (selectedShiftRef.current.day || selectedShiftRef.current.night) {

    setLoading(true);
    fetchFunc(); // Initial fetch
    // } else {
    //   setLoading(false);
    // }

    // Cleanup the timeout when the component unmounts or dependencies change
    return () => {
      if (timeout) {
        clearTimeout(timeout); // Clear the existing timeout
      }
    };
  }, [shiftType, selectedShift]); // Keep this dependency array

  async function setHourShiftResponse(res, response) {
    const result = response?.res?.data?.data?.[shiftTypeRef.current];
    setShiftStartTime({
      date: res?.res?.data?.data[shiftTypeRef.current]?.shiftDate?.substr(0, 10),
      type: result?.shiftType,
    });

    if (res?.res?.data?.data) {
      setShiftData({
        ...result,
        reportNo: res.res.data.data[shiftTypeRef.current].reportNo,
        status: res.res.data.data[shiftTypeRef.current].status,
        _id: res?.res?.data?.data[shiftTypeRef.current]?._id
      });
    }
    setbreakdownExcavatorsOldData(result?.breakdownExcavators)

    if (result?.excavators) {
      excavatorFreshData.current = result?.excavators?.filter((ex) => {
        return ex?.haulTrucks.length != 0;
      });
    }

    if (result?.excavators) {
      setExcavators(
        result?.excavators?.filter((ex) => {
          return ex?.haulTrucks.length != 0;
        })
      );
    } else {
      setExcavators([])
    }

    if (result?.trucks) {
      setTrucks(result?.trucks);
    } else {
      setTrucks([]);
    }

    if (result?.excavators) {
      setNotOperatingExcavators(
        result?.excavators
          ?.filter((ex) => ex?.breakdown)
          .map((ex) => ex.name.Unit)
      );
    } else {
      setNotOperatingExcavators([])
    }

    if (result?.trucks) {
      setNotOperatingTrucks(
        result?.trucks
          ?.filter((truck) => truck?.breakdown)
          .map((truck) => truck.name.Unit)
      );
    } else {
      setNotOperatingTrucks([])
    }

    const timeDiffValue = timeDiff(res?.res?.data?.data[shiftTypeRef.current]?.startTime);

    if (res?.res?.data?.data) {
      setShiftStartingAndEndingTime({
        start: res.res.data.data[shiftTypeRef.current].startTime,
        end: res.res.data.data[shiftTypeRef.current].endTime,
      });
    }
    setHasOneHourElapsed(timeDiffValue > 3600);
    if (res?.res?.data?.data) {
      setBehindTargetExcavators(
        result?.excavators
          ?.filter((ex) =>
            getAlert(
              timeDiffValue,
              ex.productive_hours,
              ex.actualLoads,
              ex.targetLoads
            )
          )
          .map((ex) => ex.name.Unit)
      );

      setBehindTargetTrucks(
        result?.trucks
          ?.filter((truck) =>
            getAlert(
              timeDiffValue,
              truck?.productive_hours,
              truck.actualLoads,
              truck.targetLoads
            )
          )
          .map((ex) => ex.name.Unit)
      );

      setShiftAlert(
        getAlert(
          timeDiffValue,
          res.res?.data?.data?.[shiftTypeRef.current]?.startTime,
          res.res?.data?.data?.[shiftTypeRef.current]?.endTime,
          result?.currentBCM?.actualLoads,
          result?.currentBCM?.targetLoads
        )
      );
    }

    setLoading(false);
    isDataFetching.current = false;
  }

  async function callApiForHourShiftData(res, shiftIds) {
    try {
      const response = await CallWithAuthFormData('POST', SHIFT_HOUR_DATA, {
        date: selectedDateRef.current,
        dayShiftId: selectedShiftRef.current.day?.value,
        nightShiftId: selectedShiftRef.current.night?.value,
      });

      if (response?.res?.data?.data?.length > 0) {
        await setHourShiftResponse(res, response);
      }
      else {
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching hour shift data:', error);
      // Handle error as needed
    }
  }


  const fetchData = async () => {
    if (isDataFetching.current) return;

    isDataFetching.current = true;

    try {
      const { userData } = auth || {};
      const { current: selectedDate } = selectedDateRef;
      const { day: selectedDay, night: selectedNight } = selectedShiftRef.current;

      // Fetch shift details
      const shiftDetailsResponse = await CallWithAuthFormData("POST", SHIFT_DASHBOARD_LISTING_DETAILS, {
        userId: userData?._id,
        date: selectedDate,
        dayShiftId: selectedDay?.value,
        nightShiftId: selectedNight?.value,
      });

      const shiftDetailsData = shiftDetailsResponse?.res?.data?.data || {};
      const shiftType = shiftTypeRef.current;

      // Update truck and excavator options
      setoptionsForTruck(
        shiftDetailsData[shiftType]?.trucks?.map(truck => ({
          label: truck.truck.equipments,
          value: truck.truck.equipments,
        })) || []
      );

      setoptionsForExacavator(
        shiftDetailsData[shiftType]?.excavators?.map(excavator => ({
          label: excavator.unit.equipments,
          value: excavator.unit.equipments,
        })) || []
      );

      await callApiForHourShiftData(shiftDetailsResponse);

      // Set default screen if not loaded and URL matches
      if (!screenLoadedRef.current && window.location.href.includes("tvdashboard")) {
        const defaultShiftType = shiftDetailsData[1]?.[0]?._id ? 1 : 0;
        shiftTypeRef.current = defaultShiftType;
        setDefaultScreen(defaultShiftType);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      isDataFetching.current = false;
      setLoading(false);
    }
  };

  const getTodayAndTomorrowData = async () => {
    if (!isFetchingTodayTomorrowData.current) {
      isFetchingTodayTomorrowData.current = true;
      const response = await CallWithAuth("GET", GET_PRESENT_TOMMOROW_DATA);

      let nextDayData = response?.res?.data?.data?.data?.tomorrowData;
      let presentDayData = response?.res?.data?.data?.data;

      setTodayData({
        temperature: presentDayData?.temperature,
        max_temp: presentDayData?.max_temp,
        min_temp: presentDayData?.min_temp,
        rain_so_far: presentDayData?.rain_so_far,
        feels_like: response?.res?.data?.data?.feel_data,
      });
      setTomorrowData({
        cloud_cover: nextDayData?.cloud_cover,
        humidity: nextDayData?.humidity,
        max_temp: nextDayData?.max_temp,
        min_temp: nextDayData?.min_temp,
        rain_so_far: nextDayData?.rain_so_far,
        uv: nextDayData?.uv,
        wind_direction: nextDayData?.wind_direction,
        wind_gusts: nextDayData?.wind_gusts,
        wind_speed: nextDayData?.wind_speed,
        pressure: nextDayData?.pressure,
      });
      isFetchingTodayTomorrowData.current = false;
    }
  };
  const getForcastData = async () => {
    if (!isFetchingForcastData.current) {
      isFetchingForcastData.current = true;
      let payload = {
        timestamp: Math.floor(new Date().getTime() / 1000),
      };
      const response = await CallWithAuth(
        "POST",
        GET_PRESENT_FORECAST_DATA,
        payload
      );
      setForecast({
        temp: response?.res?.data?.message?.temperature,
        cloudCover: response?.res?.data?.message?.cloudCover,
      });
      isFetchingForcastData.current = false;
    }
  };
  const getWebhookData = async () => {
    if (!isFetchingWebhookData.current) {
      isFetchingWebhookData.current = true;
      const response = await CallWithAuth("GET", GET_WEBHOOK_DATA);
      const data = response?.res?.data?.message;
      if (data) {
        setWebHookData({
          temp: data?.temp,
          wind: data?.wind,
          windDirection: data?.windDirection,
          pressure: data?.pressure,
          rain: data?.rain,
          dust: data?.dust,
        });
        isFetchingWebhookData.current = false;
      }
    }
  };

  useEffect(() => {
    const fetchIN = async () => {
      getTodayAndTomorrowData();
      getWebhookData();
      callOperator();
    };
    fetchIN();
    let refr = setInterval(() => {
      getTodayAndTomorrowData();
      getForcastData();
      getWebhookData();

    }, 15000);

    return () => {
      clearInterval(refr);
    };
  }, []);

  const generateCanvasPdf = () => {
    let dashboard_header = document.getElementById("dashboard_header");
    let height1 = dashboard_header.getBoundingClientRect().height;

    let button_header = document.getElementById("button_header");
    button_header.style.removeProperty("box-shadow");
    let height2 = button_header.getBoundingClientRect().height;

    let excavator_part = document.getElementById("excavator_part");
    excavator_part.style.removeProperty("box-shadow");
    let height3 = excavator_part.getBoundingClientRect().height;

    let truck_part = document.getElementById("truck_part");
    let height4 = truck_part.getBoundingClientRect().height;

    let truck_cover = document.getElementsByClassName("truck_cover");
    for (var i = 0; i < truck_cover.length; i++) {
      truck_cover[i].style.removeProperty("border-right");
    }

    html2canvas(dashboard_header).then((canvas1) => {
      const imgData1 = canvas1.toDataURL(" image/jpeg");
      html2canvas(button_header).then((canvas2) => {
        const imgData2 = canvas2.toDataURL(" image/jpeg");
        html2canvas(excavator_part).then((canvas3) => {
          const imgData3 = canvas3.toDataURL(" image/jpeg");
          html2canvas(truck_part)
            .then((canvas4) => {
              const imgData4 = canvas4.toDataURL(" image/jpeg");
              let pdf = new jsPDF("1", "px", [
                1480,
                height1 + height2 + height3 + height4 + 200,
              ]);

              pdf.addImage(imgData1, "JPEG", 0, 40, 1400, height1);
              pdf.addImage(imgData2, "JPEG", 0, height1 + 50, 1480, height2);
              pdf.addImage(imgData3, "JPEG", 10, height2 + 140, 1470, height3);
              pdf.addImage(imgData4, "JPEG", 10, height3 + 250, 1480, height4);

              pdf.save("Hourly Report");
            })
            .then(() => {
              button_header.style.boxShadow = "rgba(0, 0, 0, 0.16) 0px 3px 6px";
              for (var i = 0; i < truck_cover.length; i++) {
                truck_cover[i].style.borderRight = "1px solid #E1E8F4";
              }
            });
        });
      });

      // document.getElementById("main").style.paddingTop = "0px";
    });
    // excavator_part.style.boxShadow="0px 0px 4px 2px #8989895e"
  };

  const generateSimplePDFByCanvas = () => {
    let dashboard_header = document.getElementById("dashboard_header");
    let height1 = dashboard_header.getBoundingClientRect().height;

    let truck_excavator_summary = document.getElementById(
      "truck_excavator_summary"
    );
    truck_excavator_summary.style.removeProperty("box-shadow");

    // button_header.style.removeProperty("box-shadow");
    let height2 = truck_excavator_summary.getBoundingClientRect().height;

    let excavator_heading = document.getElementById("excavator_heading");
    // y_scroll
    let y_scroll = document.getElementById("y_scroll");
    y_scroll.style.overflowY = "visible";
    let height3 = excavator_heading.getBoundingClientRect().height;

    let excavatorCard_container = document.getElementById(
      "excavatorCard_container"
    );
    let height4 = excavatorCard_container.getBoundingClientRect().height;

    let excavator_card_container = document.getElementsByClassName(
      "excavator_card_container"
    );
    for (var i = 0; i < excavator_card_container.length; i++) {
      excavator_card_container[i].style.removeProperty("box-shadow");
    }

    let remove_scroll = document.getElementsByClassName('remove_scroll');
    {
      for (var i = 0; i < remove_scroll.length; i++) {
        remove_scroll[i].style.height = "max-content";
      }
    }

    let remove_scroll_container = document.getElementsByClassName(
      "remove_scroll_container"
    );
    {
      for (var i = 0; i < remove_scroll_container.length; i++) {
        remove_scroll_container[i].style.height = "max-content";
      }
    }

    html2canvas(dashboard_header).then((canvas1) => {
      const imgData1 = canvas1.toDataURL(" image/jpeg");
      html2canvas(truck_excavator_summary).then((canvas2) => {
        const imgData2 = canvas2.toDataURL(" image/jpeg");
        html2canvas(excavator_heading).then((canvas3) => {
          const imgData3 = canvas3.toDataURL(" image/jpeg");
          html2canvas(excavatorCard_container)
            .then((canvas4) => {
              const imgData4 = canvas4.toDataURL(" image/jpeg");
              let pdf = new jsPDF("1", "px", [
                1480,
                height1 + height2 + height3 + height4 + 200,
              ]);

              pdf.addImage(imgData1, "JPEG", 0, 40, 1400, height1);
              pdf.addImage(imgData2, "JPEG", 0, height1 + 50, 1480, height2);
              pdf.addImage(imgData3, "JPEG", 20, height2 + 140, 1480, height3);
              topSlider
                ? pdf.addImage(
                  imgData4,
                  "JPEG",
                  0,
                  height3 + 500,
                  1480,
                  height4
                )
                : pdf.addImage(
                  imgData4,
                  "JPEG",
                  0,
                  height3 + 200,
                  1480,
                  height4
                );

              pdf.save("Dashboard Report");
            })
            .then(() => {
              truck_excavator_summary.style.boxShadow =
                "rgba(0, 0, 0, 0.06) 0px 8px 6px;";
              for (var i = 0; i < excavator_card_container.length; i++) {
                excavator_card_container[i].style.boxShadow =
                  "rgb(0 0 0 / 16%) 0px 3px 6px";
              }
              for (var i = 0; i < remove_scroll.length; i++) {
                remove_scroll[i].style.height = '120px';
              }

              for (var i = 0; i < remove_scroll_container.length; i++) {
                remove_scroll_container[i].style.height = '230px';
              }
              y_scroll.style.overflowY = "scroll";
            });
        });
      });
    });
  };

  // const generateSimplePDF = async () => {
  //   let payload = {
  //     details: shiftData,
  //     shiftStartTime: shiftStartTime,
  //     behindTargetExcavators: behindTargetExcavators,
  //     behindTargetTrucks: behindTargetTrucks,
  //     notOperatingTrucks: notOperatingTrucks,
  //     notOperatingExcavators: notOperatingExcavators,
  //     excavators: excavators,
  //     trucks: trucks,
  //     shiftStartingAndEndingTime:shiftStartingAndEndingTime,
  //     shiftAlert:shiftAlert,

  //   };
  //   const response = await CallWithAuth("POST", GENERATE_PDF, payload);
  //   const linkSource = `data:application/pdf;base64,${response?.res?.data}`;
  //   const downloadLink = document.createElement("a");
  //   const fileName = "ShiftReport.pdf";

  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  const [popup, setPopup] = useState();

  const endShifts = async (closePopup) => {
    const response = await CallWithAuth("POST", END_SHIFT, {
      type: "all",
      shiftId: shiftData?._id,
    });
    if (response?.res?.data?.status === 200) {
      toast.success("Shifts ended successfully.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      closePopup();
    } else {
      toast.error("An error occured when ending the shifts.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <AuthLayout pageName="Dashboard">
      <div className="right_sidebar_body" id="main">
        <div style={{ height: "100%", overflow: "hidden" }}>
          {modalShow && (
            <WeatherReport
              setModalShow={setModalShow}
              forecast={forecast}
              webhookData={webhookData}
              todayData={todayData}
              tomorrowData={tomorrowData}
            />
          )}

          {isFilterModalOpen && (
            <FilterModal
              filterVariableAfterApply={filterVariableAfterApply}
              onFilterApply={(v) => {
                setFilterVariableAfterApply(v);

                setisFilterModalOpen(false);
              }}
              onCancel={() => setisFilterModalOpen(false)}
              trucks={trucks}
              excavators={excavators}
              optionsForExacavator={optionsForExacavator}
              optionsForOperator={optionsForOperator}
              optionsForStatus={optionsForStatus}
              optionsForTruck={optionsForTruck}
            />
          )}
          <div
            className=" d-flex align-items-center justify-content-between pb-3"
            style={{
              width: "100%",
              paddingInline: 20,
              paddingTop: "2rem",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
            }}
            id="dashboard_header"
          >
            <section className="d-flex">
              <hgroup className="d-flex" style={{ gap: 5 }}>
                <h1
                  style={{
                    fontSize: "1.7rem",
                    lineHeight: "2.5rem",
                    marginBottom: 0,
                  }}
                >
                  {shiftData?.reportNo ? `#${shiftData?.reportNo} ${shiftData?.shiftType} Shift` : `${shiftType == 0 ? "Day" : "Night"} Shift`}
                </h1>
                <p
                  style={{
                    color: "#707070",
                    fontWeight: "bold",
                    marginBottom: 0,
                  }}
                >
                  (
                  {shiftData?.reportNo ? shiftData?.status === "O"
                    ? "Ongoing"
                    : shiftData?.status === "P"
                      ? "Published"
                      : "Ended" : "No Records"}
                  )
                </p>
              </hgroup>
              {shiftData?.status == "O" && (
                <button
                  disabled={true}
                  className="btn btn-outline-info"
                  onClick={() => {
                    return
                    setPopup({
                      title: "You’re about to end the shift for ALL EQUIPMENT",
                      description: "Would you like to continue?",
                      confirmText: "Confirm",
                      onCancel: () => {
                        setPopup(undefined);
                      },
                      onConfirm: () => {
                        endShifts(() => setPopup(undefined));
                      },
                    });
                  }}
                  style={{ marginLeft: 20 }}
                >
                  End shift for all equipment
                </button>
              )}
            </section>

            <div className="d-flex align-items-center">
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  style={{ height: "2.5rem", width: "6rem" }}
                  bsPrefix="custom-toggle"
                  id=""
                >
                  Legend
                </Dropdown.Toggle>

                <Dropdown.Menu bsPrefix="dropdown-menu custom-menu">
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <div
                        style={{
                          width: "0px",
                          height: "15px",
                          border: " 1.6px solid #000000",
                          borderRadius: "7px",
                        }}
                      />
                    </div>
                    <div className="dashboard__header__bar__label">Target</div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#95C365 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Target Achieved
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#ED7C7F 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Below Target
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#707070 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Unsheduled
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <Image
                      src={require("../../assets/images/not-available.png")}
                      height={18}
                      width={18}
                      style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                      Not operating
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <Image
                      src={require("../../assets/images/alert.png")}
                      height={16}
                      width={18}
                      style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                      Falling behind
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <TrendArrow backward={true} />
                    </div>
                    <div className="dashboard__header__bar__label">
                      Backward trend
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "18px", marginRight: 7 }}
                    >
                      <TrendArrow backward={false} />
                    </div>
                    <div className="dashboard__header__bar__label">
                      Upward trend
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                  >
                    <div
                      style={{
                        background: "#BFD4F9 0% 0% no-repeat padding-box",
                      }}
                      className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                      Ongoing cycle{" "}
                      <span style={{ fontSize: "10px" }}>
                        (on hourly results)
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="d-flex mx-3">
                <Form.Group controlId="formBasicSelect">
                  <Form.Control
                    as="select"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    style={{
                      fontSize: "1rem",
                      borderRadius: "18px",
                      color: "black",
                      padding: "1px 7px",
                      width: "10.5rem",
                    }}
                  >
                    <option value="Overburden">Overburden</option>
                    <option value="Coal">Coal</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="mr-3" style={{ width: "180px" }}>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                />
              </div>
              <div
                className="m-3 d-flex"
                style={{
                  backgroundColor: "rgb(240,244,251)",
                  borderRadius: "45%",
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      shiftType == 0 ? "rgb(203,220,248)" : "rgb(240,244,251)",
                    color: shiftType == 0 ? "black" : "#96a4bb",

                    borderWidth: "0px",
                    cursor: "pointer",
                    fontSize: "1rem",
                    fontWeight: "600",
                    padding: "0px 1.3rem",
                    borderRadius: "15px",
                  }}
                  key={0}
                  name="radio"
                  value={0}
                  onClick={(e) => {
                    shiftTypeRef.current = 0;
                    setShiftType(0);
                  }}
                >
                  Day
                </div>
                <div
                  style={{
                    backgroundColor:
                      shiftType == 1 ? "rgb(20,36,62)" : "rgb(241,244,251)",

                    color: shiftType == 1 ? "white" : "#96a4bb",

                    borderWidth: "0px",
                    cursor: "pointer",
                    fontSize: "1rem",
                    fontWeight: "600",
                    padding: "0px 1rem",
                    borderRadius: "15px",
                  }}
                  key={1}
                  name="radio"
                  value={1}
                  onClick={(e) => {
                    shiftTypeRef.current = 1;
                    setShiftType(1);
                  }}
                >
                  Night
                </div>
              </div>
              <DynamicSelectField
                placeholder={"Select ReportNo."}
                wrapperStyle={{ marginBottom: 0, marginRight: '5px' }}
                value={selectedShift[shiftType == 0 ? "day" : "night"]}
                options={shiftsOptions[shiftType == 0 ? "day" : "night"]}
                onChange={(v) => {
                  setSelectedShift((prev) => ({
                    ...prev,
                    [shiftType == 0 ? "day" : "night"]: v
                  }))

                  selectedShiftRef.current[shiftType == 0 ? "day" : "night"] = v
                }}
              />
              <FiDownload
                onClick={
                  radioValue == 2
                    ? generateCanvasPdf
                    : generateSimplePDFByCanvas
                }
                style={{
                  color: "black",
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: ".4rem",
                  width: "2.4rem",
                  height: "2.4rem",
                  border: "1.5px solid rgb(225,232,244)",
                }}
              />

              <ButtonGroup
                className="ml-3"
                style={{ backgroundColor: "rgb(240,244,251)" }}
              >
                {radios.map((radio, idx) => (
                  <div
                    style={{
                      backgroundColor:
                        radioValue == idx + 1 ? "black" : "rgb(240,244,251)",
                      color: radioValue == idx + 1 ? "white" : "#96a4bb",
                      borderRadius: "5px",
                      borderWidth: "0px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      padding: "0px 1rem",
                    }}
                    key={idx}
                    name="radio"
                    value={radio.value}
                    onClick={(e) => setRadioValue(idx + 1)}
                  >
                    {radio.name}
                  </div>
                ))}
              </ButtonGroup>
            </div>
          </div>
          <>
            <div style={{ overflow: "hidden", height: "calc(100vh - 150px)" }}>
              {radioValue == 1 && !loading && (
                <>
                  {/* <DashboradHeader /> */}
                  <div
                    id="truck_excavator_summary"
                    style={{
                      width: "100%",
                      background:
                        "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",
                      boxShadow: "rgb(0 0 0 / 6%) 0px 8px 6px",
                    }}
                  >
                    <div
                      style={{
                        transition:
                          "height .25s ease-in,opacity .25s ease-in,padding .25s ease-in",
                      }}
                      className={
                        (topSlider
                          ? "dashboard__top__slider__show"
                          : "dashboard__top__slider__hide") +
                        " d-flex justify-content-between px-4"
                      }
                    >
                      <div
                        className="px-3"
                        style={{
                          overflow: "hidden",
                          width: "35%",
                          background: "#eaf7fd 0% 0% no-repeat padding-box",
                          borderRadius: "8px",
                          height: 230,
                        }}
                      >
                        <div
                          className="py-2"
                          style={{ borderBottom: "1px solid #c0e7e9" }}
                        >
                          <Image
                            src={require("../../assets/images/PERFORMANCE.png")}
                            height={35}
                            width={35}
                          />
                          <span
                            style={{
                              paddingLeft: "15px",
                              font: "normal normal 600 16px/22px Open Sans",
                            }}
                          >
                            Performance
                          </span>
                        </div>
                        <div
                          style={{ width: "100%", height: "calc(100% - 43px)" }}
                          className="d-flex align-items-start"
                        >
                          <div
                            style={{
                              width: "60%",
                              margin: " 10px 0",
                              height: "84%",
                              borderRight: "1px solid #999595",
                            }}
                          >
                            <span
                              style={{
                                paddingLeft: "5px",
                                color: "#707070",
                                font: "normal normal 600 12px/17px Open Sans",
                              }}
                            >
                              Totals:
                            </span>
                            <div
                              style={{
                                margin: "10px  0",
                                font: "normal normal bold 20px/30px Open Sans",
                              }}
                            >
                              {shiftAlert && (
                                <Image
                                  src={require("../../assets/images/alert.png")}
                                  height={20}
                                  width={20}
                                  style={{ marginTop: "-5px" }}
                                />
                              )}{" "}
                              {shiftData?.currentBCM?.actualLoads}{" "}
                              <span
                                style={{
                                  color: "#707070",
                                  font: "normal normal normal 20px/30px Open Sans",
                                }}
                              >
                                / {shiftData?.currentBCM?.targetLoads} BCM
                              </span>
                              <div style={{ margin: "10px 2px" }}>
                                {" "}
                                <ProgressBar
                                  progress={
                                    (shiftData?.currentBCM?.actualLoads /
                                      shiftData?.currentBCM?.targetLoads) *
                                    100
                                  }
                                  notCatching={shiftAlert}
                                  fallingBehind={shiftAlert}
                                  height={19}
                                  width={220}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between pr-4 mr-3">
                              <div className="d-flex flex-column">
                                <span
                                  style={{
                                    color: "#707070",
                                    marginBottom: "7px",
                                    font: "normal normal 600 12px/17px Open Sans",
                                  }}
                                >
                                  To target:
                                </span>
                                <span
                                  style={{
                                    marginBottom: "7px",
                                    font: " normal normal 600 15px/21px Open Sans",
                                  }}
                                >
                                  {parseInt(
                                    shiftData?.currentBCM?.targetLoads
                                  ) -
                                    parseInt(
                                      shiftData?.currentBCM?.actualLoads
                                    ) >
                                    0
                                    ? parseInt(
                                      shiftData?.currentBCM?.targetLoads
                                    ) -
                                    parseInt(
                                      shiftData?.currentBCM?.actualLoads
                                    )
                                    : 0}{" "}
                                  BCM
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <span
                                  style={{
                                    marginBottom: "7px",
                                    color: "#707070",
                                    font: "normal normal 600 12px/17px Open Sans",
                                  }}
                                >
                                  Previous shift total:
                                </span>
                                <span
                                  style={{
                                    color: "#707070",
                                    marginBottom: "1px",
                                    font: "normal normal 600 15px/21px Open Sans",
                                  }}
                                >
                                  {shiftData?.previousBCM?.actualLoads}
                                  <span
                                    style={{
                                      font: "normal normal normal 15px/21px Open Sans",
                                    }}
                                  >
                                    / {shiftData?.previousBCM?.targetLoads} BCM
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              width: "40%",
                              padding: "15px 0",
                              flexDirection: "column",
                              paddingLeft: "8px",
                              justifyContent: "space-around",
                            }}
                          >
                            <span
                              style={{
                                color: "black",
                                fontSize: "14.5px",
                                fontWeight: "700",
                              }}
                            >
                              Breakdown:
                            </span>
                            <div className="d-flex flex-column">
                              <span
                                style={{
                                  color: "#707070",
                                  marginBottom: "7px",
                                  font: "normal normal 600 12px/17px Open Sans",
                                }}
                              >
                                BCM within geofences:
                              </span>
                              <span
                                style={{
                                  marginBottom: "7px",
                                  font: " normal normal 600 15px/21px Open Sans",
                                }}
                              >
                                {(parseInt(shiftData?.currentBCM?.actualLoads)
                                  ? parseInt(shiftData?.currentBCM?.actualLoads)
                                  : 0) -
                                  (parseInt(shiftData?.unknownBCM)
                                    ? parseInt(shiftData?.unknownBCM)
                                    : 0)}{" "}
                                BCM
                              </span>
                            </div>
                            <div className="d-flex flex-column">
                              <span
                                style={{
                                  color: "#707070",
                                  marginBottom: "7px",
                                  font: "normal normal 600 12px/17px Open Sans",
                                }}
                              >
                                BCM outside geofences{" "}
                                <span style={{ fontStyle: "italic" }}>
                                  (unknown)
                                </span>
                                :
                              </span>
                              <span
                                style={{
                                  marginBottom: "7px",
                                  font: " normal normal 600 15px/21px Open Sans",
                                }}
                              >
                                {parseInt(shiftData?.unknownBCM)
                                  ? parseInt(shiftData?.unknownBCM)
                                  : 0}{" "}
                                BCM
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="equipment_falling_behind_reference_container"
                        className="remove_scroll_container"
                        style={{
                          overflow: "hidden",
                          padding: "5px 10px",
                          width: "22.5%",
                          background:
                            "0% 0% no-repeat padding-box padding-box rgb(252, 240, 218)",
                          borderRadius: "8px",
                          minHeight: 230,
                          height: 230,
                          color: "rgb(112, 112, 112)",
                          font: '12px/17px "Open Sans"',
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Image
                            src={require("../../assets/images/alert_circle.png")}
                            height={35}
                            width={35}
                          />{" "}
                          <span
                            style={{
                              font: "normal normal 600 14px/20px Open Sans",
                              color: "black",
                              paddingLeft: "10px",
                            }}
                          >
                            Equipment falling behind
                          </span>
                        </div>
                        <div
                          className="d-flex px-2"
                          style={{ width: "100%", paddingTop: 20 }}
                        >
                          <div
                            style={{ width: "50%" }}
                            className="d-flex flex-column align-items-center"
                          >
                            <span
                              style={{
                                paddingBottom: "4px",
                                borderBottom: "1px solid rgb(234, 222, 91)",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              Excavators
                            </span>
                            {/* {hasOneHourElapsed && ( */}
                            <div
                              id="equipment_falling_behind_excavator"
                              className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                              style={{
                                width: "100%",
                                minHeight: 120,
                                height: 120,
                                overflowX: "auto",
                                borderRight: ".5px solid #EADE5B",
                              }}
                            >
                              {behindTargetExcavators?.map((name, _idx) => (
                                <span
                                  key={_idx}
                                  style={{
                                    font: "normal normal normal 12px/16px Open Sans",
                                    fontWeight: "500",
                                    height: "min-content",
                                    padding: "10px 10px 10px 0px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {name}
                                </span>
                              ))}
                            </div>
                            {/* )} */}
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="d-flex flex-column align-items-center"
                          >
                            <span
                              style={{
                                paddingBottom: "4px",
                                borderBottom: "1px solid #EADE5B",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              Trucks
                            </span>
                            {/* {hasOneHourElapsed && ( */}
                            <div
                              id="equipment_falling_behind_truck"
                              className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                              style={{
                                overflowX: "auto",
                                width: "100%",
                                minHeight: 120,
                                height: 120,
                                borderLeft: ".5px solid #EADE5B",
                              }}
                            >
                              {behindTargetTrucks?.map((name, _idx) => (
                                <span
                                  key={_idx}
                                  style={{
                                    font: "normal normal normal 12px/16px Open Sans",
                                    fontWeight: "500",
                                    height: "min-content",
                                    padding: "10px 0px 10px 10px",
                                    paddingLeft: "10px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {name}
                                </span>
                              ))}
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>

                      <div
                        id="equipment_not_operating_reference_container"
                        className="remove_scroll_container"
                        style={{
                          overflow: "hidden",
                          padding: "5px 10px",
                          width: "22.5%",
                          background: " #FBEEE6 0% 0% no-repeat padding-box",
                          borderRadius: "8px",
                          minHeight: 230,
                          height: 230,
                          color: "rgb(112, 112, 112)",
                          font: '12px/17px "Open Sans"',
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Image
                            src={require("../../assets/images/not_available_circle.png")}
                            height={35}
                            width={35}
                          />{" "}
                          <span
                            style={{
                              font: "normal normal 600 14px/20px Open Sans",
                              color: "black",
                              paddingLeft: "10px",
                            }}
                          >
                            Equipment not operating
                          </span>
                        </div>
                        <div
                          className="d-flex px-2"
                          style={{ width: "100%", paddingTop: 20 }}
                        >
                          <div
                            style={{ width: "50%" }}
                            className="d-flex flex-column align-items-center"
                          >
                            <span
                              style={{
                                paddingBottom: "4px",
                                borderBottom: "1px solid #ED7C7F",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              Excavators
                            </span>
                            <div
                              id="equipment_not_operating_excavator"
                              className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                              style={{
                                width: "100%",
                                minHeight: 120,
                                height: 120,
                                overflowX: "auto",
                                borderRight: ".5px solid #ED7C81",
                              }}
                            >
                              {notOperatingExcavators?.map((eq, _idx) => (
                                <span
                                  key={_idx}
                                  style={{
                                    font: "normal normal normal 12px/16px Open Sans",
                                    fontWeight: "500",
                                    height: "min-content",
                                    padding: "10px 10px 10px 0px",
                                    textDecoration: "underline",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {eq}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="d-flex flex-column align-items-center"
                          >
                            <span
                              style={{
                                paddingBottom: "4px",
                                borderBottom: "1px solid #ED7C7F",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              Trucks
                            </span>
                            <div
                              id="equipment_not_operating_truck"
                              className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                              style={{
                                overflowX: "auto",
                                width: "100%",
                                minHeight: 120,
                                height: 120,
                                borderLeft: ".5px solid #ED7C81",
                              }}
                            >
                              {notOperatingTrucks?.map((eq, _idx) => (
                                <span
                                  key={_idx}
                                  style={{
                                    font: "normal normal normal 12px/16px Open Sans",
                                    fontWeight: "500",
                                    height: "min-content",
                                    padding: "10px 0px 10px 10px",
                                    paddingLeft: "10px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {eq}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          width: "15%",
                          paddingLeft: "10px",
                          // marginLeft: "30px",
                          background:
                            "0% 0% no-repeat padding-box padding-box rgb(222, 235, 255)",
                          boxShadow: "rgba(82, 77, 77, 0.17) 1px 6px 5px 0px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          // minHeight: 230,
                          // height: 230,
                        }}
                        onClick={() => setModalShow(true)}
                        id="weather"
                        className="d-flex flex-column align-items-center"
                      >
                        <div
                          style={{ margin: "10px 0" }}
                          className="d-flex flex-column align-items-center"
                        >
                          <span
                            style={{
                              font: "normal normal 600 15px/20px Open Sans",
                              color: "#1A1A1A",
                            }}
                          >
                            Cloudy
                          </span>
                          <span
                            style={{
                              font: " normal normal bold 30px/41px Open Sans",
                            }}
                          >
                            {webhookData.temp}
                          </span>
                          <span
                            style={{
                              font: "normal normal normal 12px/17px Open Sans",
                            }}
                          >
                            Feels like{" "}
                            {(todayData.feels_like - 273.15).toFixed(1)}°C
                          </span>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            height: "100%",
                            padding: "0 10px",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <IoIosRainy color="gray" size={20} />
                            </span>
                            <span
                              style={{
                                width: 30,
                                font: "normal normal normal 12px/17px Open Sans",
                              }}
                            >
                              Rainfall
                            </span>
                            <span
                              style={{
                                width: 110,
                                font: "normal normal 600 12px/17px Open Sans",
                              }}
                            >
                              {webhookData?.rain}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <FiWind color="gray" size={20} />
                            </span>
                            <span
                              style={{
                                width: 30,
                                font: "normal normal normal 12px/17px Open Sans",
                              }}
                            >
                              Wind
                            </span>
                            <span
                              style={{
                                width: 110,
                                font: "normal normal 600 12px/17px Open Sans",
                              }}
                            >
                              {webhookData.wind} {webhookData.windDirection}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <WiDust color="gray" size={20} />
                            </span>
                            <span
                              style={{
                                width: 30,
                                font: "normal normal normal 12px/17px Open Sans",
                              }}
                            >
                              Dust
                            </span>
                            <span
                              style={{
                                width: 110,
                                font: "normal normal 600 12px/17px Open Sans",
                              }}
                            >
                              {webhookData?.dust} &mu;g/m<sup>3</sup>
                            </span>
                          </div>
                        </div>
                        <ImEnlarge2
                          style={{
                            fontSize: "12px",
                            position: "absolute",
                            right: "13px",
                            top: "15px",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "max-content",
                        padding: "5px 25px",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingBottom: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: topSlider ? "#F2F7FF" : "#011358",
                          height: 16,
                          width: 23,
                          border: "1px solid #E1E8F4",
                        }}
                        className="d-flex justify-content-center"
                        onClick={() => setTopSlider(!topSlider)}
                      >
                        {topSlider ? (
                          <TiArrowSortedUp />
                        ) : (
                          <TiArrowSortedDown color="white" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    id="y_scroll"
                    style={{
                      padding: "20px",
                      overflowY: "scroll",
                      height:
                        "calc(100vh - " + (topSlider ? "450px)" : "220px)"),
                    }}
                  >
                    <div
                      id="excavator_heading"
                      style={{ margin: "15px 20px" }}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="d-flex align-items-center">
                        <Image
                          src={require("../../assets/images/jcb.png")}
                          height={30}
                          width={40}
                        />
                        <span
                          className="ml-2"
                          style={{ fontWeight: "600", fontSize: "23px" }}
                        >
                          Excavators{" "}
                          <span style={{ fontWeight: 400 }}>(BCM)</span>
                        </span>
                      </div>
                      <div
                        style={{ border: "0px solid black", display: "flex" }}
                      >
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid #dde5f3",
                            padding: "6px 12px",
                            borderRadius: "15px",
                            width: "250px",
                            backgroundColor: "white",
                          }}
                        >
                          <input
                            type="search"
                            id="searchuser"
                            className="shadow-none"
                            name="searchuser"
                            value={searchName}
                            placeholder="Search for an Excavator"
                            style={{
                              border: "none",
                              font: "normal normal normal 14px Open Sans",
                              width: "200px",
                            }}
                            onChange={(v) => setsearchName(v.target.value)}
                          />

                          <i
                            style={{ fontSize: "13px" }}
                            className="fa-regular fa-magnifying-glass"
                          ></i>
                        </div>
                        <div
                          style={{
                            width: "25px",
                            backgroundColor: "rgb(240, 244, 251)",
                            borderRadius: "50%",
                            height: "25px",
                            objectFit: "contain",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1.5px solid rgb(225,232,244)",
                            cursor: "pointer",
                            // border:"2px solid black"
                          }}
                        >
                          <MdOutlineFilterList
                            color={isFilterApplied ? "red" : "black"}
                            fontSize={"20px"}
                            onClick={() => {
                              setisFilterModalOpen((prev) => !prev);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id="excavatorCard_container"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {excavators
                        ?.filter(
                          (ex) =>
                            ex?.name?.Unit.toLowerCase()?.includes(
                              searchName?.toLowerCase()
                            ) && filterData(ex)
                        )
                        ?.map((excavator, i) => (
                          <ExcavatorsCard
                            key={i}
                            trucks={trucks}
                            {...excavator}
                            shiftStartingAndEndingTime={
                              shiftStartingAndEndingTime
                            }
                            breakdownExcavatorsOldData={
                              breakdownExcavatorsOldData
                            }
                            notOperatingExcavators={notOperatingExcavators}
                            notOperatingTrucks={notOperatingTrucks}
                            behindTargetExcavators={behindTargetExcavators}
                            behindTargetTrucks={behindTargetTrucks}
                            shiftStartTime={shiftStartTime}
                          />
                        ))}
                    </div>
                  </div>
                </>
              )}
              {radioValue == 2 && !loading && (
                <HourlyResult
                  notOperatingExcavators={notOperatingExcavators}
                  notOperatingTrucks={notOperatingTrucks}
                  behindTargetExcavators={behindTargetExcavators}
                  behindTargetTrucks={behindTargetTrucks}
                  shiftData={shiftData}
                  navigation={props.navigation}
                  breakdownExcavatorsOldData={breakdownExcavatorsOldData}
                  excavators={excavators}
                  trucks={trucks}
                  shiftType={shiftStartTime?.type}
                  shiftStartTime={shiftStartTime}
                  shiftStartingAndEndingTime={shiftStartingAndEndingTime}
                />
              )}
            </div>
            {loading && <LoadingScreen />}
            {popup && <ConfirmationPopup {...popup} />}
          </>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Dashboard;

export const ShiftListPopUp = ({
  changetab,
  publishShiftPlan,
  DuplicateShiftPlanHandler,
  callDownload,
  DeleteShiftPlanHandler,
  userValue,
  userIndex,
}) => {
  return (
    <div className="ellipse_dropdown">
      <span className="ellipse_dropdown_button">
        <i
          className="fa-regular fa-ellipsis-vertical"
          onClick={(e) => changetab(userIndex, e)}
        ></i>
      </span>
      {userValue?.openTab && (
        <ul>
          {userValue.status === "D" && (
            <li onClick={() => publishShiftPlan(userValue._id)}>
              <Link to="">Publish</Link>
            </li>
          )}
          {userValue.status !== "O" && (
            <li>
              <Link to={`/add-daily-plan?id=${userValue._id}`}>
                {userValue.status === "E" ? "View" : "Edit"}
              </Link>
            </li>
          )}
          <li onClick={() => callDownload(userValue)}>Export</li>
          <li onClick={() => DuplicateShiftPlanHandler(userValue._id)}>
            <Link to="">Duplicate</Link>
          </li>
          {userValue.status === "D" && (
            <li onClick={() => DeleteShiftPlanHandler(userValue._id)}>
              <Link to="">Delete</Link>
            </li>
          )}
          {userValue.status === "O" && (
            <li>
              <Link to={`/add-daily-plan?id=${userValue._id}`}>
                Change Plan
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
