import React, { useEffect, useState } from 'react';
import TextInputField from '../../../components/GeneralFields/TextInputField';
import DynamicSelectField from '../../../components/DynamicSelectField/DynamicSelectField';
import DateTimeSelect from '../../../components/GeneralFields/DateTimeSelect/DateTimeSelect';
import RadioSelect from '../../../components/GeneralFields/RadioSelect';
import SelectField from '../../../components/GeneralFields/SelectField';
import OperatorSelect from '../../../components/OperatorSelect/OperatorSelect';
import TextAreaField from '../../../components/GeneralFields/TextAreaField';
import { DateTimeFormats } from '../../../utils/contants';
import moment from 'moment';
import { getDurationBetweenMomentsInHHMM } from '../../../utils/helper';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { useAddDailyPlan } from '../../../Context/context';


export default function ShiftDetails({ formData, setFormData, showError, viewOnly }) {
    const {
        minesList,
        departmentsList,
        crewTypesList,
        supervisorList,
        leadingHandList
    } = useAddDailyPlan();

    const [isMandateErrorOpen, setIsMandateErrorOpen] = useState(showError);
    const handleFieldChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };
    useEffect(() => {
        setIsMandateErrorOpen(showError)
    }, [showError])

    return (
        <div>
            {isMandateErrorOpen && <ErrorMessage message={"Please make sure all the required fields marked with an asterisk are filled correctly before you save."} onClose={() => setIsMandateErrorOpen(false)} />}

            <TextInputField
                label={"Ref No"}
                required={true}
                viewOnly={viewOnly}
                isDisabled={true}
                placeholder={"0000000"}
                value={formData?.reportNo}
                error={!formData?.reportNo && showError}
                errorMessage={"Report Number is required"}
                onChange={(v) => handleFieldChange('reportNo', v)}
            />

            <DynamicSelectField
                required
                label="Mine"
                value={formData.mine}
                viewOnly={viewOnly}
                placeholder="Select Mine"
                options={minesList?.map((mine) => ({ value: mine._id, label: mine.Mine }))}
                error={showError && !formData?.mine}
                errorMessage={"Please select a mine"}
                onChange={(v) => handleFieldChange('mine', v)}
            />

            <RadioSelect
                required
                label="Shift"
                viewOnly={viewOnly}
                value={formData.shiftType}
                options={[
                    { value: 'Day', label: 'Day' },
                    { value: 'Night', label: 'Night' }
                ]}
                error={showError && !formData?.shiftType}
                errorMessage={"Please select a shift type"}
                onChange={(v) => {
                    if (v === "Day") {
                        handleFieldChange('startTime', moment().set({ hour: 6, minute: 0 }).format(DateTimeFormats.DATE_TIME_U));
                        handleFieldChange('endTime', moment().set({ hour: 17, minute: 0 }).format(DateTimeFormats.DATE_TIME_U));
                    } else {
                        handleFieldChange('startTime', moment().set({ hour: 17, minute: 0 }).format(DateTimeFormats.DATE_TIME_U));
                        handleFieldChange('endTime', moment().add('day', 1).set({ hour: 4, minute: 0 }).format(DateTimeFormats.DATE_TIME_U));
                    }
                    handleFieldChange('shiftType', v);
                }}
                id="shift"
            />

            <DynamicSelectField
                required
                label="Department"
                viewOnly={viewOnly}
                placeholder="Select Department"
                value={formData.department}
                options={departmentsList?.map((department) => ({ value: department._id, label: department.DepartmentType }))}
                error={showError && !formData?.department}
                errorMessage={"Please select a Department"}
                onChange={(v) => handleFieldChange('department', v)}
            />

            <DynamicSelectField
                required
                isSearchable={true}
                label="Crew"
                viewOnly={viewOnly}
                placeholder="Select Crew"
                value={formData.crew}
                options={crewTypesList?.map((crew) => ({ value: crew._id, label: crew.CrewType }))}
                error={showError && !formData?.crew}
                errorMessage={"Please select a Crew"}
                onChange={(v) => handleFieldChange('crew', v)}
            />

            <DateTimeSelect
                required
                minDate={new Date(moment().subtract(1, 'day').toISOString())}
                minTime={new Date(moment().startOf('day').toISOString())}
                maxDate={new Date(moment().add(1, 'year').toISOString())}
                maxTime={new Date(moment().add(1, 'year').endOf('day').toISOString())}
                viewOnly={viewOnly}
                label="Shift Start"
                value={new Date(moment(formData.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newStartTime = moment(v).format(DateTimeFormats.DATE_TIME_U);
                    // const newEndTime = moment(formData.endTime, DateTimeFormats.DATE_TIME_U)
                    // if (moment(newEndTime, DateTimeFormats.DATE_TIME_U).diff(moment(newStartTime, DateTimeFormats.DATE_TIME_U), 'minutes') >= 15) {
                    handleFieldChange('startTime', newStartTime);
                    handleFieldChange('endTime', moment(v).add(11, 'hours').add(15, 'minutes').format(DateTimeFormats.DATE_TIME_U));
                    // } else {
                    // Handle error or notification about minimum difference requirement
                    // }
                }}
                error={showError && !formData?.startTime}
                errorMessage="Please select shift start time"
                id="shiftStart"
            />

            <DateTimeSelect
                required
                minDate={new Date(moment(
                    Math.max(
                        moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                        Date.now() + 600000
                    )
                ).toISOString())}
                maxTime={new Date(moment(formData.startTime, DateTimeFormats.DATE_TIME_U).endOf('day').toISOString())}
                maxDate={new Date(moment().add(1, 'year').toISOString())}
                minTime={
                    moment(
                        Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                    ).format('YYYY-MM-DD') !== moment(formData.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? moment().startOf('day').valueOf()
                        : Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                }
                label="Shift End"
                viewOnly={viewOnly}
                value={new Date(moment(formData.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newEndTime = moment(v).format(DateTimeFormats.DATE_TIME_U);


                    // if (moment(newEndTime, DateTimeFormats.DATE_TIME_U).diff(moment(newStartTime, DateTimeFormats.DATE_TIME_U), 'minutes') >= 15) {
                    handleFieldChange('endTime', newEndTime);
                    // } else {
                    //     // Handle error or notification about minimum difference requirement
                    // }
                }}
                error={showError && moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 0}
                errorMessage="End Time can't be less than current time"
                id="shiftEnd"
            />

            <TextInputField
                label={"Shift Duration"}
                required={true}
                isDisabled={true}
                viewOnly={viewOnly}
                value={getDurationBetweenMomentsInHHMM(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), moment(formData.endTime, DateTimeFormats.DATE_TIME_U))}
                error={showError && moment(formData?.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(formData?.startTime, DateTimeFormats.DATE_TIME_U), 'minutes') <= 0}
                errorMessage="Duration can't be negative"
            />

            <DynamicSelectField
                required
                viewOnly={viewOnly}
                placeholder="Select Hours"
                label="Productive Hours"
                value={formData.productiveHours}
                options={Array.from({ length: moment(formData.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), 'hours') }, (_, index) => ({ value: index + 1, label: (index + 1).toString() }))}
                error={showError && (!formData?.productiveHours || formData?.productiveHours?.value > moment(formData.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), 'hours'))}
                errorMessage={!formData?.productiveHours ? "Please select productive hours" : "Productive Hours are greater than duration"}
                onChange={(v) => handleFieldChange('productiveHours', v)}
            />

            <DynamicSelectField
                required
                label="Shift Supervisor"
                viewOnly={viewOnly}
                placeholder="Select Shift Supervisor"
                isSearchable={true}
                value={formData.shiftSupervisor}
                options={supervisorList?.map((supervisor) => ({
                    value: supervisor._id,
                    label: `${supervisor.firstName} ${supervisor.lastName || ""}`.trim()
                }))}
                error={showError && !formData?.shiftSupervisor}
                errorMessage={"Please select a superviosr"}
                onChange={(v) => handleFieldChange('shiftSupervisor', v)}
            />

            <DynamicSelectField
                label="Leading Hand"
                isSearchable={true}
                viewOnly={viewOnly}
                placeholder="Select Leading Hand"
                value={formData.leadingHand}
                options={leadingHandList?.map((leadingHand) => ({
                    value: leadingHand._id,
                    label: `${leadingHand.firstName} ${leadingHand.lastName || ""}`.trim()
                }))}
                onChange={(v) => handleFieldChange('leadingHand', v)}
            />

            <OperatorSelect
                label={"Annual Leave"}
                isMulti={true}
                value={formData.annualLeave}
                initialCrew={formData?.crew?.label}
                viewOnly={viewOnly}
                error={
                    showError &&
                    [...formData.sickLeave, ...formData.absent].some(v =>
                        formData.annualLeave?.find(iv => iv.value === v.value)
                    )
                }
                placeholder="-"
                errorMessage={"Some operators are already selected in other Leave Types"}
                onChange={(v) => handleFieldChange('annualLeave', v?.map((fullName) => ({ value: fullName?.value, label: fullName?.label?.split(" ")[0] })))}
            />

            <OperatorSelect
                label={"Sick Leave"}
                isMulti={true}
                value={formData.sickLeave}
                viewOnly={viewOnly}
                initialCrew={formData?.crew?.label}
                error={
                    showError &&
                    [...formData.annualLeave, ...formData.absent].some(v =>
                        formData.sickLeave?.find(iv => iv.value === v.value)
                    )
                }
                placeholder="-"
                errorMessage={"Some operators are already selected in other Leave Types"}
                onChange={(v) => handleFieldChange('sickLeave', v?.map((fullName) => ({ value: fullName?.value, label: fullName?.label?.split(" ")[0] })))}
            />

            <OperatorSelect
                label={"Absent"}
                isMulti={true}
                value={formData.absent}
                viewOnly={viewOnly}
                initialCrew={formData?.crew?.label}
                error={
                    showError &&
                    [...formData.sickLeave, ...formData.annualLeave].some(v =>
                        formData.absent?.find(iv => iv.value === v.value)
                    )
                }
                placeholder="-"
                errorMessage={"Some operators are already selected in other Leave Types"}
                onChange={(v) => handleFieldChange('absent', v?.map((fullName) => ({ value: fullName?.value, label: fullName?.label?.split(" ")[0] })))}
            />

            <TextAreaField
                label={"General Shift Notes"}
                isDisabled={false}
                style={{ height: 75 }}
                viewOnly={viewOnly}
                placeholder={"-"}
                value={formData.generalShiftNotes}
                onChange={(v) => handleFieldChange('generalShiftNotes', v)}
            />
        </div>
    );
}
